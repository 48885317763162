<template>
  <div>
    <!-- 头部标题 -->
    <el-header>
      <div class="title">认证审核管理</div>
    </el-header>

    <!-- 主内容区 -->
    <el-main>
      <el-tabs v-model="activeTab" @tab-click="handleTabClick">
        <!-- 居民认证 -->
        <el-tab-pane label="居民认证" name="resident">
          <el-table :data="CertificationList" stripe border>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column prop="community" label="社区"></el-table-column>
            <el-table-column prop="residentialArea" label="小区"></el-table-column>
            <el-table-column prop="doorNumber" label="门牌号"></el-table-column>
            <el-table-column prop="active" label="认证状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.active === 0" type="warning">审核中</el-tag>
                <el-tag v-else-if="scope.row.active === 1" type="success">已通过</el-tag>
                <el-tag v-else-if="scope.row.active === -1" type="danger">已拒绝</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="handleApprove(scope.row, 'resident')">通过</el-button>
                <el-button type="danger" size="mini" @click="handleReject(scope.row, 'resident')">驳回</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <!-- 党员认证 -->
        <el-tab-pane label="党员认证" name="partyMember">
          <el-table :data="CertificationList" stripe border>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column prop="community" label="社区"></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
            <el-table-column prop="active" label="认证状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.active === 0" type="warning">审核中</el-tag>
                <el-tag v-else-if="scope.row.active === 1" type="success">已通过</el-tag>
                <el-tag v-else-if="scope.row.active === -1" type="danger">已拒绝</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="handleApprove(scope.row, 'partyMember')">通过</el-button>
                <el-button type="danger" size="mini" @click="handleReject(scope.row, 'partyMember')">驳回</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <!-- 党组织认证 -->
        <el-tab-pane label="党组织认证" name="partyOrganization">
          <el-table :data="CertificationList" stripe border>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column prop="community" label="社区"></el-table-column>
            <el-table-column prop="organizationName" label="组织名称"></el-table-column>
            <el-table-column prop="organizationCode" label="组织代码"></el-table-column>
            <el-table-column prop="active" label="认证状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.active === 0" type="warning">审核中</el-tag>
                <el-tag v-else-if="scope.row.active === 1" type="success">已通过</el-tag>
                <el-tag v-else-if="scope.row.active === -1" type="danger">已拒绝</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="primary" size="mini"
                  @click="handleApprove(scope.row, 'partyOrganization')">通过</el-button>
                <el-button type="danger" size="mini"
                  @click="handleReject(scope.row, 'partyOrganization')">驳回</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </div>
</template>

<script>

import { getVerifyList ,shenhe} from '@/api/user'

export default {
  data() {
    return {
      activeTab: 'resident', // 默认显示居民认证
      CertificationList: [

      ]
    };
  },
  created() {
    this.verifyType = "resident";
    getVerifyList(this.verifyType).then(res => {
      if (res.data) {
        console.log(res.data);
        this.CertificationList = res.data;
      }
    }).catch(error => {
      console.error(error);
    });
  },
  methods: {
    handleTabClick(tab) {
      // 切换标签页时，更新 activeTab
      this.activeTab = tab.name;
      getVerifyList(this.activeTab).then(res => {
        if (res.data) {
          console.log(res.data);
          this.CertificationList = res.data;
        }
      }).catch(error => {
        console.error(error);
      });
    },
    handleApprove(row, type) {
      // 处理通过审核的逻辑，可以调用后端接口等
      row.active = 1;
      shenhe(row).then(res => {
        this.$message.success('信息提交成功');

      }).catch(error => {
        console.error(error);
        this.$message.error('信息提交失败');
      });

    },
    handleReject(row, type) {
      // 处理驳回审核的逻辑，可以调用后端接口等
      row.active = -1;
      shenhe(row).then(res => {
        this.$message.success('信息提交成功');

      }).catch(error => {
        console.error(error);
        this.$message.error('信息提交失败');
      });
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 20px;
  color: #409EFF;
  margin-left: 20px;
  margin-top: 10px;
}

.el-main {
  padding: 20px;
}
</style>