import request from '@/utils/request'

// 用户主页
export function getInfoByName(username, page, size) {
  return request({
    url: '/ums/user/' + username,
    method: 'get',
    params: {
      pageNo: page,
      size: size
    }
  })
}
// 用户主页
export function getInfo() {
  return request({
    url: '/ums/user/info',
    method: 'get'
  })
}
// 更新
export function update(user) {
  return request({
    url: '/ums/user/update',
    method: 'post',
    data: user
  })
}
//认证
export function verify(verifyInfo) {
  return request({
    url: '/verify/add',
    method: 'post',
    data: verifyInfo
  })
}

export function getVerifyInfo(verifyType) {
  return request({
    url: '/verify/get/'+verifyType,
    method: 'get'
    
  })
}

export function getTree() {
  return request({
    url: '/dept/getTree',
    method: 'get'

  })
}

export function getAllTree() {
  return request({
    url: '/dept/getAllTree',
    method: 'get'

  })
}

export function setCheckedDepts(CheckedDepts) {
  return request({
    url: '/dept/setCheckedDepts',
    method: 'post',
    data:CheckedDepts
  })
}


//预约
export function book(bookInfo) {
  return request({
    url: '/book/add',
    method: 'post',
    data: bookInfo
  })
}

export function getBooks() {
  return request({
    url: '/book/listme',
    method: 'get'

  })
}

export function getVerifyList(verifyType) {
  return request({
    url: '/verify/list/'+verifyType,
    method: 'get'
  })
}

export function shenhe(verifyInfo) {
  return request({
    url: '/verify/shenhe',
    method: 'post',
    data: verifyInfo
  })
}
